import React from 'react';
import { Container } from 'reactstrap';
import EmailToolbar from './EmailToolbar';
import BreadCrumb from '../../Components/Common/BreadCrumb';

const MailInbox = () => {
    document.title="Project Mail";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                   <BreadCrumb title="Project Mail" pageTitle="Pages" />
 
                    <div className="email-wrapper d-lg-flex gap-1 mx-n4 mt-n1 p-1">
                        <EmailToolbar />
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default MailInbox;