import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { api, url } from "../helpers/api";
import { ToastMessage } from "../Components/Hooks/Function";
import { getLoggedinUser, getSettingsDetails } from "../helpers/api_helper";

const Footer = () => {
    const [settingsList, setSettingsList] = useState([]);
    const getSettings = async () => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get('settingsDetails', {});
            if (response.data.status === true) {
                setSettingsList(response.data?.data);
                let link = document.querySelector("link[rel~='icon']");
                if (!link) {
                    link = document.createElement('link');
                    link.rel = 'icon';
                    document.getElementsByTagName('head')[0].appendChild(link);
                }
                link.href = url + response.data?.data?.filter(type => type?.name === "icon")[0]?.value;

            } else {

                setSettingsList([]);
                ToastMessage("error", response.data?.message);
            }

        } catch (err) {
            console.error(err.message);
        }
    };

    useEffect(() => {
        const changeLogo = () => {
            getSettings();
        };

        setSettingsList(getSettingsDetails("settings"));

        window.addEventListener("changeLogo", changeLogo);
        return () => {
            window.removeEventListener("changeLogo", changeLogo);
        };
    }, []);

    return (
        <React.Fragment>
            <footer className="footer">
                <Container fluid>
                    <Row>
                        <Col sm={6}>
                        © {" 2024"}, Design & Develop by {settingsList?.filter(type => type?.name === "name")[0]?.value}
                        </Col>
                        <Col sm={6}>
                            <div className="text-sm-end d-none d-sm-block">
                                
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </React.Fragment>
    );
};

export default Footer;