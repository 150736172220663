import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
    Card, CardBody, Offcanvas, OffcanvasHeader,
    OffcanvasBody, Col, Button, Container, Form, Input, Label, Modal, ModalBody, Row, ModalHeader, ModalFooter, UncontrolledDropdown, FormFeedback
} from 'reactstrap';
import DeleteModal from "../../Components/Common/DeleteModal";
import { ToastContainer, toast } from 'react-toastify';
import { api, url } from "../../helpers/api";
import SimpleBar from "simplebar-react";
import { useSelector, } from 'react-redux';
import * as Yup from "yup";

import { IoCloseOutline } from "react-icons/io5";
import { useMediaQuery } from 'react-responsive';
import Pagination from "react-js-pagination";
import { ToastMessage } from "../../Components/Hooks/Function";
import { useFormik } from "formik";
import Select from "react-select";
import { FaCheck, FaTimes, FaPlus } from "react-icons/fa";


const Server = () => {
    document.title = "Server";
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const placement = isDesktop ? 'end' : 'start';
    const [submit, setSubmit] = useState(false);
    const [searchList, setSearchList] = useState([]);

    // const [deleteModal, setDeleteModal] = useState(false); 
    const [serverList, setServerList] = useState([]);
    const [serverView, setServerView] = useState([]);
    //Modal  
    const [view, setView] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowPerPage, setRowPerPage] = useState(50);
    const [serverModal, setServerModal] = useState(false);

    const [tempMailServerId, setTempMailServerId] = useState();
    const [tempSmsServer_id, setTempSmsServerId] = useState();


    const [open, setOpen] = useState(false);
    const toggleDrawer = () => {
        setOpen(!open);
    };


    const selectStyle = (fieldName) => {
        return {
            control: (baseStyles, state) => ({
                ...baseStyles,
                height: '30px',
                minHeight: '30px',
                padding: '0px',
                borderColor: state.isFocused ? 'grey' : submit && validation.errors[fieldName] ? "#f06548" : '#ced4da'
            }),
            option: (provided, state) => ({
                ...provided,
                background: state.isFocused ? ' ' : ' ',
            }),

            valueContainer: (provided, state) => ({
                ...provided,
                height: '30px',
                padding: '0 6px'
            }),

            input: (provided, state) => ({
                ...provided,
                margin: '0px',
            }),
            indicatorSeparator: state => ({
                display: 'none',
            }),
            indicatorsContainer: (provided, state) => ({
                ...provided,
                height: '30px',
            }),
            indicatorContainer: (provided, state) => ({
                ...provided,
                padding: state.isFocused ? '4px' : '0px', // Change padding on focus

            }),
            singleValue: (provided, state) => ({
                ...provided,
                marginTop: '-4px',
            }),
        };
    };

    const getServers = async () => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get(`server?page=${currentPage}&perPage=${rowPerPage}`, {});
            if (response.data.status === true) {

                console.log("--------responce");
                console.log(response.data?.data);
                setServerList(response.data?.data);
                setSearchList(response.data?.data);
            } else {
                setServerList([]);
                setSearchList([]);
                ToastMessage("error", response.data?.message);
            }

        } catch (err) {
            console.error(err.message);
        }

    };

    const getServerPage = async (currentPage, rowPerPage) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get(`server?page=${currentPage}&perPage=${rowPerPage}`);
            if (response.data.status === true) {
                setSearchList(response.data?.data);
                setServerList(response.data?.data);
            } else {
                setSearchList([]);
                setServerList([]);
                ToastMessage("error", response.data?.message);
            }

        } catch (err) {
            console.error(err.message);
        }

    };

    const viewServer = async (id) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get('server/' + id, {});
            if (response.data.status === true) {

                setServerView(response.data?.data);
                validation.setValues(response.data?.data);
                setTempMailServerId(response.data?.data?.mail_server_id);
                setTempSmsServerId(response.data?.data?.sms_server_id);


            } else {
                setServerView([]);
            }


            setOpen(true);

        } catch (err) {
            console.error(err.message);
        }

    };


    const handleAddClick = (arg) => {
        setView(false);
        setSubmit(false);
        validation.resetForm();
        validation.setValues({
            status: true
        });
        setIsEdit(false);
        toggleDrawer();
    };


    useEffect(() => {
        getServers();

    }, []);

    const handleViewClick = (arg) => {
        setView(true);
        viewServer(arg?.id);
        setIsEdit(false);
    };

    const handleEditClick = (arg) => {
        setView(false);
        setSubmit(false);
        viewServer(arg?.id);
        setIsEdit(true);
    };

    const keywordSearch = async (e) => {
        let inputVal = e;
        if (inputVal !== "") {
            const filteredResults = searchList?.data?.filter(item =>
                item.name?.toLowerCase().includes(inputVal.toLowerCase())
                // ||
                // item.employee?.employee_number_id?.toLowerCase().includes(inputVal.toLowerCase()) ||
                // item.email?.toLowerCase().includes(inputVal.toLowerCase()) ||
                // item.mobile?.toLowerCase().includes(inputVal.toLowerCase()) ||
                // item.roles?.[0]?.name?.toLowerCase().includes(inputVal.toLowerCase())
            );

            setServerList({
                ...serverList,
                data: filteredResults
            });

        } else {
            setServerList({
                ...serverList,
                data: searchList?.data
            });
        }
    };

    const handleClick = (e) => {
        setCurrentPage(e);
        getServerPage(e, rowPerPage);
    };


    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            name: '',
            type: '',
           
        },
        validationSchema: Yup.object({
            name: Yup.string().required("required"), 
            type: Yup.string().required("required"),
        }),
        onSubmit: (values) => {
            if (isEdit) {
                editServer(values, serverView?.id);
            } else {
                addServer(values);
            }
        },
    });



    const severDetailValidation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            value: '',

        },
        validationSchema: Yup.object({

            value: Yup.string().required("required"),

        }),
        onSubmit: (values) => {
            if (isEdit) {
                editSeverDetail(values, serverView?.id);
            }
        },
    });

    const editSeverDetail = async (value) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.put('serverDetails/' + value.id, value);

            if (response.data.status === true) {

                viewServer(validation.values?.id);

                setServerModal(false);
                severDetailValidation.resetForm();
                ToastMessage("success", response.data?.message);

            } else {
                ToastMessage("error", response.data?.message);
            }

        } catch (err) {
            console.error(err.message);
        }

    };


    const addServer = async (value) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.post('server', value);
            if (response.data.status === true) {
                getServers();
                setOpen(false);
                setIsEdit(false);
                validation.resetForm();
                ToastMessage("success", response.data?.message);

            } else {
                ToastMessage("error", response.data?.message);
            }

        } catch (err) {
            console.error(err.message);
        }

    };

    const editServer = async (value, id) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.put('server/' + id, value);

            if (response.data.status === true) {
                getServers();
                setOpen(false);
                setIsEdit(false);
                validation.resetForm();
                ToastMessage("success", response.data?.message);

            } else {
                ToastMessage("error", response.data?.message);
            }

        } catch (err) {
            console.error(err.message);
        }

    };


    const updateServer = async (value, id) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json';
        try {
            const response = await api.put('server/' + id, value);

            if (response.data.status === true) {
                viewUpdateServer(id);
            }

        } catch (err) {
            console.error(err.message);
        }

    };


    const viewUpdateServer = async (id) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get('server/' + id, {});
            if (response.data.status === true) {

                setServerView(response.data?.data);
                validation.setValues(response.data?.data);


            } else {
                setServerView([]);
            }


        } catch (err) {
            console.error(err.message);
        }

    };



    return (
        <React.Fragment>
            <ToastContainer closeButton={false} />
            {/* <DeleteModal
                name={serverView?.name}
                show={deleteModal}
                onDeleteClick={() => deleteServers(serverView)}
                onCloseClick={() => setDeleteModal(false)}
            /> */}
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs={12} className='mb-1 mb-md-0 '>
                            <div className="mb-2  page-title-box borderColor d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-0 fw-bold px-3 px-md-0" >Server</h4>
                            </div>
                        </Col>
                    </Row>

                    <Row className='d-flex align-items-center'  >
                        <Col sm={4}>

                        </Col>
                        <Col className="col-sm-auto ms-auto d-flex gap-2">


                            <div className="search-box">
                                <Input type="text" className="form-control py-1" placeholder="Search..." onChange={(e) => keywordSearch(e.target.value)} />
                                <i className="ri-search-line search-icon"></i>
                            </div>

                            <div className="list-grid-nav hstack gap-1">
                                <button color="primary" className="btn btn-primary shadow-lg d-none d-md-flex gap-2 px-3 py-1" onClick={() => handleAddClick()} >
                                    <i className="ri-add-line"  ></i>  <span className='d-none d-md-block'> Server</span></button>
                                <FaPlus className="text-primary d-flex d-md-none" size={18} onClick={() => { handleAddClick(); }} />
                            </div>
                        </Col>
                    </Row>

                    <Row className="row  gy-1 mb-1  mt-1  px-2 "  >
                        <Col lg={12} className='table px-1  mb-0 '  >
                            <Card className="mb-0 d-none d-md-flex mt-1 px-1 table-light border-0 rounded-0">
                                <CardBody className="px-1 py-0 px-md-2 py-md-0 border-0">
                                    <div className="row d-lg-flex align-items-center   px-0 py-2 d-none d-md-none d-lg-block">
                                        <div className="col-6 px-1">
                                            <h5 className="fs-15 mb-0"> Name</h5>
                                        </div>

                                        <div className="col-6 col-md-5  px-2 mt-0 align-items-center d-flex justify-content-end  justify-content-md-start">
                                            <h5 className="fs-15 mb-0">Type</h5>
                                        </div>

                                        <div className="mb-1 col-12 col-md-1 d-flex gap-2 align-items-center justify-content-end">
                                            <h5 className="fs-15 mb-0">  Actions</h5>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>


                    <Row className="row gy-1 mb-1" id="server-list">
                        {serverList?.data?.length !== 0 ?
                            (serverList?.data)?.map((item, index) => (
                                <Col lg={12} key={index} className='mt-0 '  >

                                    <Card className="mb-0 mt-0 border-0 border-bottom rounded-0 py-1 ">
                                        <CardBody className="px-1 py-0">

                                            <div className="d-lg-flex align-items-center row">

                                                <div className="col-6  ">  <p className="mb-0">{item.name} </p> </div>


                                                <div className="col-6 col-md-5    d-flex align-items-center justify-content-end justify-content-md-start">

                                                    <p className="mb-0"> {item?.type} </p>

                                                </div>


                                                <div className="col-12 col-md-1    d-flex justify-content-end">


                                                    <button type="button" className="btn  btn-sm btn-icon"
                                                        onClick={(e) => { handleEditClick(item); }}>
                                                        <i className="ri-pencil-fill fs-18 primary"  ></i>
                                                    </button>

                                                    <button type="button" className="btn  btn-sm btn-icon" onClick={(e) => { handleViewClick(item); }}>   <i className="ri-eye-fill fs-18 primary"  ></i>  </button>

                                                    {/* <button type="button" className="btn btn-sm btn-icon"   
                                                          onClick={(e) => { onClickData(); setServerView(item); } }>
                                                                       < i className="ri-delete-bin-fill fs-18 secondary" ></i>

                                                                   </button>     */}

                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            ))

                            :
                            <div className="noresult" >
                                <div className="text-center">
                                    <h6 className="mt-2">Sorry! No Result Found</h6>
                                    {/* <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any  orders for you search.</p> */}
                                </div>
                            </div>
                        }
                    </Row>

                    <div className=" d-flex justify-content-end  pagination-wrap hstack gap-2">
                        <div className="col-sm-6 d-flex gap-2 justify-content-end">
                            <select className="pagination-prev disabled  h-100 px-1 py-2" type="select" name="perPage" id="perPage"
                                onChange={(e) => {
                                    setRowPerPage(e.target.value);
                                    getServerPage(1, e.target.value);
                                }}
                                value={rowPerPage}
                            >

                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={serverList?.total}>All</option>

                            </select>


                            <Pagination
                                activePage={currentPage}
                                itemsCountPerPage={50}
                                totalItemsCount={serverList?.total || 0}
                                pageRangeDisplayed={10}
                                onChange={handleClick}
                                itemClass="page-item"
                                linkClass="page-link"
                            />

                        </div>

                    </div>



                    <Offcanvas isOpen={open} toggle={function noRefCheck() { }} direction={placement} className={`offcanvas-${placement} border-0 offcanvas-600`}     >
                        <OffcanvasHeader className="d-flex align-items-center bg-light p-3" toggle={function noRefCheck() { }}   >
                            <span className="d-flex justify-content-between" >
                                <span className="m-0 me-2">{view ? "View Server" : isEdit ? "Update Server" : "Add Server"}</span>
                                <span>
                                    <span className="fs-18 cursor" onClick={() => {

                                        toggleDrawer();

                                        if (isEdit) {
                                            updateServer({
                                                ...serverView,
                                                mail_server_id: tempMailServerId,
                                                sms_server_id: tempSmsServer_id
                                            }, serverView?.id);
                                        }


                                    }}>   <IoCloseOutline /> </span>
                                </span>

                            </span>
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0">
                            <SimpleBar className="h-100">

                                {view && <div className="px-4 py-3">
                                    <Row>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label className="form-label fw-bold"> Name </Label>  <br />  {serverView.name}
                                            </div>

                                        </Col>

                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label className="form-label fw-bold"> Type </Label>   <br />  {serverView?.type}
                                            </div>
                                        </Col>

                                    </Row>
                                </div>}



                                {!view && <div className="px-4 py-3">
                                    <Form onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                    }}>
                                        <Row>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="name" className="form-label">Name <span className='secondary'>*</span></Label>
                                                    <Input type="text" className="form-control px-2 py-1" id="name" placeholder="Enter name"
                                                        name='name'
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.name || ""}
                                                        invalid={submit && validation.errors.name ? true : false}
                                                    />

                                                </div>
                                            </Col>

                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="type" className="form-label">Type <span className='secondary'>*</span></Label>
                                                    <Input type="text" className="form-control px-2 py-1" id="type" placeholder="Enter type"
                                                        name='type'
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.type || ""}
                                                        invalid={submit && validation.errors.type ? true : false}
                                                    />

                                                </div>
                                            </Col>


                                            <Col lg={12}>
                                                <div className="hstack gap-2 justify-content-end">
                                                    <button type="submit" className="btn btn-primary shadow-lg" id="addNewMember" onClick={() => {
                                                        setSubmit(true);
                                                    }}>{!isEdit ? "Add" : "Save"}</button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>}

 

                            </SimpleBar>
                        </OffcanvasBody>
                    </Offcanvas>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Server;