import axios from "axios";
import { urlList } from "../config";


const currentDomain = window.location.origin;

let SITE_URL = '';
let API_URL = '';

if (currentDomain === 'http://localhost:3000' || currentDomain === 'http://localhost:3000') {
  SITE_URL = urlList.LOCAL_API_URL
  API_URL = urlList.LOCAL_API_URL
} else if (currentDomain === 'https://devfairmail.fairreturns.in' || currentDomain === 'http://devfairmail.fairreturns.in') {
  SITE_URL = urlList.DEV_SITE_URL
  API_URL = urlList.DEV_API_URL
} else {
  SITE_URL = urlList.LIVE_SITE_URL
  API_URL = urlList.LIVE_API_URL
}

let url = SITE_URL;

const api = axios.create({
  baseURL: API_URL,
});

export { api, url };