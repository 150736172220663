import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

const Navdata = () => {
    const history = useNavigate();
    //state data
    const [isDashboard, setIsDashboard] = useState(false);
    const [isPassword, setIsPassword] = useState(false);
    const [isSettings, setIsSettings] = useState(false);
    const [isDocuments, setIsDocuments] = useState(false);

    const [iscurrentState, setIscurrentState] = useState('Dashboard');

    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState !== 'Dashboard') {
            setIsDashboard(false);
        }
        if (iscurrentState !== 'Passwords') {
            setIsPassword(false);
        }

        if (iscurrentState !== 'Settings') {
            setIsSettings(false);
        }

        if (iscurrentState !== 'Documents') {
            setIsDocuments(false);
        }


    }, [
        history,
        iscurrentState,
        isDashboard,
        isPassword,
        isSettings,
        isDocuments,
    ]);

    const menuItems = [
        // {
        //     id: "dashboard",
        //     label: "Dashboards",
        //     icon: <FeatherIcon icon="grid" className="icon-dual" />,
        //     link: "/#",
        //     stateVariables: isDashboard,
        //     click: function (e) {
        //         e.preventDefault();
        //         setIsDashboard(!isDashboard);
        //         setIscurrentState('Dashboard');
        //         updateIconSidebar(e);
        //     },
        //     subItems: [
        //         {
        //             id: "dashboard",
        //             label: "Dashboard",
        //             link: "/dashboard",
        //             parentId: "dashboard",
        //         },
        //     ],
        // },

       
        {
            id: "mail-status",
            label: "Mail Status",
            view: true,
            icon: <FeatherIcon icon="mail" className="icon-dual" />,
            link: "/mail",
            click: function (e) {
                e.preventDefault();
            }
        },

        {
            id: "project",
            label: "Project",
            view: true,
            icon: <FeatherIcon icon="list" className="icon-dual" />,
            link: "/project",
            click: function (e) {
                e.preventDefault();
            }
        },
        {
            id: "server",
            label: "Server",
            view: true,
            icon: <FeatherIcon icon="list" className="icon-dual" />,
            link: "/server",
            click: function (e) {
                e.preventDefault();
            }
        },
        {
            id: "settings",
            label: "Settings",
            view: true,
            icon: <FeatherIcon icon="settings" className="icon-dual" />,
            link: "/settings",
            click: function (e) {
                e.preventDefault();
            }
        },

        // {
        //     id: "user",
        //     label: "User",
        //     view:   true  ,
        //     icon: <FeatherIcon icon="user" className="icon-dual" />,
        //     link: "/user",
        //     click: function (e) {
        //         e.preventDefault();
        //     }
        // },
        // {
        //     id: "settings",
        //     label: "Settings",
        //     icon: <FeatherIcon icon="settings" className="icon-dual" />,
        //     link: "/#",
        //     click: function (e) {
        //         e.preventDefault();
        //         setIsSettings(!isSettings);
        //         setIscurrentState('Settings');
        //         updateIconSidebar(e);
        //     },
        //     stateVariables: isSettings,
        //     subItems: [
        //         {
        //             id: "settings",
        //             label: "Settings",
        //             link: "/settings",
        //             parentId: "pages",
        //         },
        //         {
        //             id: "google-authenticator",
        //             label: "Google Authenticator",
        //             link: "/google-authenticator",
        //             parentId: "settings",
        //         },
        //         {
        //             id: "mail-settings",
        //             label: "Mail Settings",
        //             link: "/mail-settings",
        //             parentId: "settings",
        //         },
        //         {
        //             id: "sms-settings",
        //             label: "SMS Settings",
        //             link: "/sms-settings",
        //             parentId: "settings",
        //         },
        //         {
        //             id: "firebase-settings",
        //             label: "Firebase Settings",
        //             link: "/firebase-settings",
        //             parentId: "settings",
        //         },                

        //     ],
        // },
    ];
    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;