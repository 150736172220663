module.exports = {
  urlList: {

    LIVE_API_URL: "https://apifairmail.fairreturns.com/api/",
    LIVE_SITE_URL: "https://apifairmail.fairreturns.com/",

    DEV_API_URL: "https://apidevfairmail.fairreturns.in/api/",
    DEV_SITE_URL: "https://apidevfairmail.fairreturns.in/",

    LOCAL_API_URL: "http://127.0.0.1:8000/api/",
    LOCAL_SITE_URL: "http://127.0.0.1:8000/",


  },
};

