import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postFakeLogin,
  postJwtLogin,
} from "../../../helpers/fakebackend_helper";
import {api, url} from "../../../helpers/api";
import { loginSuccess, logoutUserSuccess, apiError,  reset_login_flag } from './reducer';
export const loginUser = (user, history) => async (dispatch) => {

  try {
    let response; 
   // dispatch(apiLoading(""));
    response = postFakeLogin({
      email: user.email,
      password: user.password,
    }); 
    var data = await response;

    if (data.status === true) {
 
      localStorage.setItem("token", data.token);
    //  api.defaults.headers.common["Authorization"] = "Bearer " + data.token;
      api.defaults.headers.common['Content-Type'] = 'application/json'
      const response = await api.get('userDetails', {});
      if (response.data.status === true) {

       
        // localStorage.setItem("authUser", JSON.stringify({
        //   email: user.email,
        //   user: response.data?.data,
        //   remember: user.remember
        // }));
      //  dispatch(loginSuccess(response.data?.data));
        // history('/passwords');
      }

    } else {
      dispatch(apiError(data.message));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const logoutUser = () => async (dispatch) => {
  localStorage.removeItem("authUser");
  localStorage.removeItem("token");
  dispatch(logoutUserSuccess(true));
};

export const socialLogin = (type, history) => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend();
      response = fireBaseBackend.socialLoginUser(type);
    }
    
    const socialdata = await response;
    if (socialdata) {

      

      
    }

  } catch (error) {
    dispatch(apiError(error));
  }
};

export const resetLoginFlag = () => async (dispatch) => {
  try {
    const response = dispatch(reset_login_flag());
    return response;
  } catch (error) {
    dispatch(apiError(error));
  }
};