import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
//import logo
import logoSm from "../assets/images/logo-sm.png";
import logoDark from "../assets/images/logo-dark.png";
import logoLight from "../assets/images/logo-light.png";

//Import Components
 import VerticalLayout from "./VerticalLayouts";
import TwoColumnLayout from "./TwoColumnLayout";
 import { Container } from "reactstrap";
import HorizontalLayout from "./HorizontalLayout";
import { api, url } from "../helpers/api";
import { ToastMessage } from "../Components/Hooks/Function";
import { getLoggedinUser, getSettingsDetails } from "../helpers/api_helper";

const Sidebar = ({ layoutType }) => {
  const [settingsList, setSettingsList] = useState([]);
  const getSettings = async () => {
      api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
      api.defaults.headers.common['Content-Type'] = 'application/json'
      try {
          const response = await api.get('settingsDetails', {});
          if (response.data.status === true) {
              setSettingsList(response.data?.data);
              let li6nk = document.querySelector("link[rel~='icon']");
              if (!link) {
                  link = document.createElement('link');
                  link.rel = 'icon';
                  document.getElementsByTagName('head')[0].appendChild(link);
              }
              link.href = url + response.data?.data?.filter(type => type?.name === "icon")[0]?.value;

          } else {

              setSettingsList([]);
              ToastMessage("error", response.data?.message);
          }

      } catch (err) {
          console.error(err.message);
      }
  };

  useEffect(() => {
      const changeLogo = () => {
          getSettings();
      };

      setSettingsList(getSettingsDetails("settings"));

      window.addEventListener("changeLogo", changeLogo);
      return () => {
          window.removeEventListener("changeLogo", changeLogo);
      };
  }, []);

  useEffect(() => {
    var verticalOverlay = document.getElementsByClassName("vertical-overlay");
    if (verticalOverlay) {
      verticalOverlay[0].addEventListener("click", function () {
        document.body.classList.remove("vertical-sidebar-enable");
      });
    }
  });

  const addEventListenerOnSmHoverMenu = () => {
    // add listener Sidebar Hover icon on change layout from setting
    if (document.documentElement.getAttribute('data-sidebar-size') === 'sm-hover') {
      document.documentElement.setAttribute('data-sidebar-size', 'sm-hover-active');
    } else if (document.documentElement.getAttribute('data-sidebar-size') === 'sm-hover-active') {
      document.documentElement.setAttribute('data-sidebar-size', 'sm-hover');
    } else {
      document.documentElement.setAttribute('data-sidebar-size', 'sm-hover');
    }
  };
  return (
    <React.Fragment>
      <div className="app-menu navbar-menu">
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src={url + settingsList?.filter(type => type?.name === "icon")[0]?.value} alt="" height="22" />
            </span>
            <span className="logo-lg">
            {url + settingsList?.filter(type => type?.name === "logo")[0]?.value &&
                      <img className="headLogo" src={url + settingsList?.filter(type => type?.name === "logo")[0]?.value}  width="80%" alt="" />
                    }
            </span>
          </Link>

          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <img src={logoSm} alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src={logoLight} alt="" height="17" />
            </span>
          </Link>
          <button
            onClick={addEventListenerOnSmHoverMenu}
            type="button"
            className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
            id="vertical-hover"
          >
            <i className="ri-record-circle-line"></i>
          </button>
        </div>
        {layoutType === "horizontal" ? (
          <div id="scrollbar">
            <Container fluid>
              <div id="two-column-menu"></div>
              <ul className="navbar-nav" id="navbar-nav">
                <HorizontalLayout />
              </ul>
            </Container>
          </div>
        ) : layoutType === 'twocolumn' ? (
          <React.Fragment>
            <TwoColumnLayout layoutType={layoutType} />
            <div className="sidebar-background"></div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <SimpleBar id="scrollbar" className="h-100">
              <Container fluid>
                <div id="two-column-menu"></div>
                <ul className="navbar-nav" id="navbar-nav">
                  <VerticalLayout layoutType={layoutType} />
                </ul>
              </Container>
            </SimpleBar>
            <div className="sidebar-background"></div>
          </React.Fragment>
        )}

       
      </div>
      <div className="vertical-overlay"></div>
    </React.Fragment>
  );
};

export default Sidebar;
