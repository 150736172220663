import React from "react";
import { Navigate } from "react-router-dom";

import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import Settings from "../pages/Settings/Settings";
import MailInbox from "../pages/EmailInbox";
import Project from "../pages/Project"; 
import Server from "../pages/Server"; 


const authProtectedRoutes = [ 
  { path: "/mail", component: <MailInbox /> },
  { path: "/project", component: <Project /> },
  { path: "/server", component: <Server /> },
  { path: "/settings", component: <Settings /> },
  // { path: "/dashboard", component: <Dashboard/> },    
  // { path: "/users", component: <User/> },      
  { path: "/",  exact: true,  component: <Navigate to="/project" />},
  { path: "*", component: <Navigate to="/project" /> },
];
 
const publicRoutes = [   
  { path: "/logout", component: <Logout /> },
  { path: "/login", component:    <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> }, 
];

export { authProtectedRoutes, publicRoutes };