import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
    Card, CardBody, Offcanvas, OffcanvasHeader,
    OffcanvasBody, Col, Button, Container, Form, Input, Label, Modal, ModalBody, Row, ModalHeader, ModalFooter, UncontrolledDropdown, FormFeedback
} from 'reactstrap';
import DeleteModal from "../../Components/Common/DeleteModal";
import { ToastContainer, toast } from 'react-toastify';
import { api, url } from "../../helpers/api";
import SimpleBar from "simplebar-react";
import { useSelector, } from 'react-redux';
import * as Yup from "yup";

import { IoCloseOutline } from "react-icons/io5";
import { useMediaQuery } from 'react-responsive';
import Pagination from "react-js-pagination";
import { ToastMessage } from "../../Components/Hooks/Function";
import { useFormik } from "formik";
import Select from "react-select";


const Project = () => {
    document.title = "Project";
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const placement = isDesktop ? 'end' : 'start';
    const [submit, setSubmit] = useState(false);
    const [searchList, setSearchList] = useState([]);

    // const [deleteModal, setDeleteModal] = useState(false); 
    const [projectList, setProjectList] = useState([]);
    const [projectView, setProjectView] = useState([]);
    //Modal  
    const [view, setView] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowPerPage, setRowPerPage] = useState(50);
    const [serverList, setServerList] = useState([]);
    const [serverModal, setServerModal] = useState(false);

    const [tempMailServerId, setTempMailServerId] = useState();
    const [tempSmsServer_id, setTempSmsServerId] = useState();


    const [open, setOpen] = useState(false);
    const toggleDrawer = () => {
        setOpen(!open);
    };


    const selectStyle = (fieldName) => {
        return {
            control: (baseStyles, state) => ({
                ...baseStyles,
                height: '30px',
                minHeight: '30px',
                padding: '0px',
                borderColor: state.isFocused ? 'grey' : submit && validation.errors[fieldName] ? "#f06548" : '#ced4da'
            }),
            option: (provided, state) => ({
                ...provided,
                background: state.isFocused ? ' ' : ' ',
            }),

            valueContainer: (provided, state) => ({
                ...provided,
                height: '30px',
                padding: '0 6px'
            }),

            input: (provided, state) => ({
                ...provided,
                margin: '0px',
            }),
            indicatorSeparator: state => ({
                display: 'none',
            }),
            indicatorsContainer: (provided, state) => ({
                ...provided,
                height: '30px',
            }),
            indicatorContainer: (provided, state) => ({
                ...provided,
                padding: state.isFocused ? '4px' : '0px', // Change padding on focus

            }),
            singleValue: (provided, state) => ({
                ...provided,
                marginTop: '-4px',
            }),
        };
    };

    const getProjects = async () => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get(`project?page=${currentPage}&perPage=${rowPerPage}`, {});
            if (response.data.status === true) {
                setProjectList(response.data?.data);
                setSearchList(response.data?.data);
            } else {
                setProjectList([]);
                setSearchList([]);
                ToastMessage("error", response.data?.message);
            }

        } catch (err) {
            console.error(err.message);
        }

    };

    const getProjectPage = async (currentPage, rowPerPage) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get(`project?page=${currentPage}&perPage=${rowPerPage}`);
            if (response.data.status === true) {
                setSearchList(response.data?.data);
                setProjectList(response.data?.data);
            } else {
                setSearchList([]);
                setProjectList([]);
                ToastMessage("error", response.data?.message);
            }

        } catch (err) {
            console.error(err.message);
        }

    };

    const viewProject = async (id) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get('project/' + id, {});
            if (response.data.status === true) {

                setProjectView(response.data?.data);
                validation.setValues(response.data?.data);
                setTempMailServerId(response.data?.data?.mail_server_id);
                setTempSmsServerId(response.data?.data?.sms_server_id);


            } else {
                setProjectView([]);
            }


            setOpen(true);

        } catch (err) {
            console.error(err.message);
        }

    };



    const getServerList = async () => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json';
        try {
            const response = await api.get(`server`, {});
            if (response.data.status === true) {
                setServerList(response.data?.data?.data);

            } else {
                setServerList([]);
                ToastMessage("error", response.data?.message);
            }

        } catch (err) {
            console.error(err.message);
        }

    };


    useEffect(() => {
        getProjects();
        getServerList();
    }, []);

    const handleViewClick = (arg) => {
        setView(true);
        viewProject(arg?.id);
        setIsEdit(false);
    };

    const handleEditClick = (arg) => {
        setView(false);
        setSubmit(false);
        viewProject(arg?.id);
        setIsEdit(true);
    };

    const keywordSearch = async (e) => {
        let inputVal = e;
        if (inputVal !== "") {
            const filteredResults = searchList?.data?.filter(item =>
                item.name?.toLowerCase().includes(inputVal.toLowerCase())
                // ||
                // item.employee?.employee_number_id?.toLowerCase().includes(inputVal.toLowerCase()) ||
                // item.email?.toLowerCase().includes(inputVal.toLowerCase()) ||
                // item.mobile?.toLowerCase().includes(inputVal.toLowerCase()) ||
                // item.roles?.[0]?.name?.toLowerCase().includes(inputVal.toLowerCase())
            );

            setProjectList({
                ...projectList,
                data: filteredResults
            });

        } else {
            setProjectList({
                ...projectList,
                data: searchList?.data
            });
        }
    };

    const handleClick = (e) => {
        setCurrentPage(e);
        getProjectPage(e, rowPerPage);
    };


    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            name: '',
            mail_from_name: '',
            mail_from_address: '',
            status: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required("required"),
            mail_from_name: Yup.string().required("required"),
            mail_from_address: Yup.string().required("required"),
            status: Yup.string().required("required"),
        }),
        onSubmit: (values) => {
            if (isEdit) {
                editProject(values, projectView?.id);
            } else {
                addProject(values);
            }
        },
    });



    const severDetailValidation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            value: '',

        },
        validationSchema: Yup.object({

            value: Yup.string().required("required"),

        }),
        onSubmit: (values) => {
            if (isEdit) {
                editSeverDetail(values, projectView?.id);
            }
        },
    });

    const editSeverDetail = async (value) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.put('serverDetails/' + value.id, value);

            if (response.data.status === true) {

                viewProject(validation.values?.id);

                setServerModal(false);
                severDetailValidation.resetForm();
                ToastMessage("success", response.data?.message);

            } else {
                ToastMessage("error", response.data?.message);
            }

        } catch (err) {
            console.error(err.message);
        }

    };


    const addProject = async (value) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.post('project', value);
            if (response.data.status === true) {
                getProjects();
                setOpen(false);
                setIsEdit(false);
                validation.resetForm();
                ToastMessage("success", response.data?.message);

            } else {
                ToastMessage("error", response.data?.message);
            }

        } catch (err) {
            console.error(err.message);
        }

    };

    const editProject = async (value, id) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.put('project/' + id, value);

            if (response.data.status === true) {
                getProjects();
                setOpen(false);
                setIsEdit(false);
                validation.resetForm();
                ToastMessage("success", response.data?.message);

            } else {
                ToastMessage("error", response.data?.message);
            }

        } catch (err) {
            console.error(err.message);
        }

    };


    const updateProject = async (value, id) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json';
        try {
            const response = await api.put('project/' + id, value);

            if (response.data.status === true) {
                viewUpdateProject(id);
            }

        } catch (err) {
            console.error(err.message);
        }

    };


    const viewUpdateProject = async (id) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get('project/' + id, {});
            if (response.data.status === true) {

                setProjectView(response.data?.data);
                validation.setValues(response.data?.data);


            } else {
                setProjectView([]);
            }


        } catch (err) {
            console.error(err.message);
        }

    };



    return (
        <React.Fragment>
            <ToastContainer closeButton={false} />
            {/* <DeleteModal
                name={projectView?.name}
                show={deleteModal}
                onDeleteClick={() => deleteProjects(projectView)}
                onCloseClick={() => setDeleteModal(false)}
            /> */}
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs={12} className='mb-1 mb-md-0 '>
                            <div className="mb-2  page-title-box borderColor d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-0 fw-bold px-3 px-md-0" >Project</h4>
                            </div>
                        </Col>
                    </Row>

                    <Row className='d-flex align-items-center'  >
                        <Col sm={4}>
                        </Col>
                        <Col className="col-sm-auto ms-auto d-flex gap-2">
                            <div className="search-box">
                                <Input type="text" className="form-control py-1" placeholder="Search..." onChange={(e) => keywordSearch(e.target.value)} />
                                <i className="ri-search-line search-icon"></i>
                            </div>
                        </Col>
                    </Row>

                    <Row className="row  gy-1 mb-1  mt-1  px-2 "  >
                        <Col lg={12} className='table px-1  mb-0 '  >
                            <Card className="mb-0 d-none d-md-flex mt-1 px-1 table-light border-0 rounded-0">
                                <CardBody className="px-1 py-0 px-md-2 py-md-0 border-0">
                                    <div className="row d-lg-flex align-items-center   px-0 py-2 d-none d-md-none d-lg-block">
                                        <div className="col-2 px-1">
                                            <h5 className="fs-15 mb-0"> Name</h5>
                                        </div>

                                        <div className="col-6 col-md-2  px-2 mt-0 align-items-center d-flex justify-content-end  justify-content-md-start">
                                            <h5 className="fs-15 mb-0">Mail Server</h5>
                                        </div>


                                        <div className="d-flex  col-6 col-md-2 mt-0 align-items-center">
                                            <h5 className="fs-15 mb-0">SMS Server</h5>
                                        </div>

                                        <div className="d-flex  col-6 col-md-2 mt-0 align-items-center">
                                            <h5 className="fs-15 mb-0">Whatsapp Server</h5>
                                        </div>


                                        <div className="  col-12 col-md-3 align-items-center">
                                            <h5 className="fs-15 mb-0" >From Name (From Address)</h5>
                                        </div>

                                        <div className="mb-1 col-12 col-md-1 d-flex gap-2 align-items-center justify-content-end">
                                            <h5 className="fs-15 mb-0">  Actions</h5>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>


                    <Row className="row gy-1 mb-1" id="project-list">
                        {projectList?.data?.length !== 0 ?
                            (projectList?.data)?.map((item, index) => (
                                <Col lg={12} key={index} className='mt-0 '  >

                                    <Card className="mb-0 mt-0 border-0 border-bottom rounded-0 py-1 ">
                                        <CardBody className="px-1 py-0">

                                            <div className="d-lg-flex align-items-center row">

                                                <div className="col-6 col-md-2 order-md-1 order-0">  <p className="mb-0">{item.name} </p> </div>



                                                <div className="col-6 col-md-2  order-1 order-md-1 d-flex align-items-center justify-content-end justify-content-md-start">

                                                    <p className="mb-0"> {item?.mail_server?.name}   {item?.mail_server?.name ? item?.mail_settings == 1 ? "(ON)" : "(OFF)" : ""} </p>

                                                </div>

                                                <div className="col-6 col-md-2 order-3 order-md-2 d-flex align-items-center">

                                                    <p className="mb-0"> {item?.sms_server?.name} {item?.sms_server?.name ? item?.sms_settings == 1 ? "(ON)" : "(OFF)" : ""} </p>

                                                </div>

                                                <div className="col-6 col-md-2 order-3 order-md-2 d-flex align-items-center">

                                                    <p className="mb-0"> {item?.whatsapp_server?.name} {item?.whatsapp_server?.name ? item?.whatsapp_settings == 1 ? "(ON)" : "(OFF)" : ""} </p>

                                                </div>

                                                <div className="col-12  order-2  order-md-2 col-md-3">

                                                    <p className="mb-0">  {item.mail_from_name + " (" + item.mail_from_address + ")"}</p>
                                                </div>


                                                <div className="col-6 col-md-1  order-4  order-md-4 d-flex justify-content-end">


                                                    <button type="button" className="btn  btn-sm btn-icon"
                                                        onClick={(e) => { handleEditClick(item); }}>
                                                        <i className="ri-pencil-fill fs-18 primary"  ></i>
                                                    </button>

                                                    <button type="button" className="btn  btn-sm btn-icon" onClick={(e) => { handleViewClick(item); }}>   <i className="ri-eye-fill fs-18 primary"  ></i>  </button>

                                                    {/* <button type="button" className="btn btn-sm btn-icon"   
                                                          onClick={(e) => { onClickData(); setProjectView(item); } }>
                                                                       < i className="ri-delete-bin-fill fs-18 secondary" ></i>

                                                                   </button>     */}

                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            ))

                            :
                            <div className="noresult" >
                                <div className="text-center">
                                    <h6 className="mt-2">Sorry! No Result Found</h6>
                                    {/* <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any  orders for you search.</p> */}
                                </div>
                            </div>
                        }
                    </Row>

                    <div className=" d-flex justify-content-end  pagination-wrap hstack gap-2">
                        <div className="col-sm-6 d-flex gap-2 justify-content-end">
                            <select className="pagination-prev disabled  h-100 px-1 py-2" type="select" name="perPage" id="perPage"
                                onChange={(e) => {
                                    setRowPerPage(e.target.value);
                                    getProjectPage(1, e.target.value);
                                }}
                                value={rowPerPage}
                            >

                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={projectList?.total}>All</option>

                            </select>


                            <Pagination
                                activePage={currentPage}
                                itemsCountPerPage={50}
                                totalItemsCount={projectList?.total || 0}
                                pageRangeDisplayed={10}
                                onChange={handleClick}
                                itemClass="page-item"
                                linkClass="page-link"
                            />

                        </div>

                    </div>



                    <Offcanvas isOpen={open} toggle={function noRefCheck() { }} direction={placement} className={`offcanvas-${placement} border-0 offcanvas-600`}     >
                        <OffcanvasHeader className="d-flex align-items-center bg-light p-3" toggle={function noRefCheck() { }}   >
                            <span className="d-flex justify-content-between" >
                                <span className="m-0 me-2">{view ? "View Project" : isEdit ? "Update Project" : "Add Project"}</span>
                                <span>
                                    <span className="fs-18 cursor" onClick={() => {

                                        toggleDrawer();

                                        if (isEdit) {
                                            updateProject({
                                                ...projectView,
                                                mail_server_id: tempMailServerId,
                                                sms_server_id: tempSmsServer_id
                                            }, projectView?.id);
                                        }


                                    }}>   <IoCloseOutline /> </span>
                                </span>

                            </span>
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0">
                            <SimpleBar className="h-100">

                                {view && <div className="px-4 py-3">
                                    <Row>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label className="form-label fw-bold"> Name </Label>  <br />  {projectView.name}
                                            </div>

                                        </Col>

                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label className="form-label fw-bold"> Mail Server </Label>   <br />  {projectView?.mail_server?.name} ({projectView?.mail_settings == 1 ? "ON" : "OFF"})
                                            </div>
                                        </Col>


                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label className="form-label fw-bold"> SMS Server </Label>   <br />  {projectView?.sms_server?.name} ({projectView?.sms_settings == 1 ? "ON" : "OFF"})
                                            </div>
                                        </Col>

                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label className="form-label fw-bold"> Whatsapp Server </Label>   <br />  {projectView?.whatsapp_server?.name} ({projectView?.whatsapp_settings == 1 ? "ON" : "OFF"})
                                            </div>
                                        </Col>

                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label className="form-label fw-bold"> Status </Label>   <br /> {projectView?.status ? "Active" : "Inactive"}
                                            </div>
                                        </Col>



                                        <Col lg={12}>

                                            <Label className="form-label fw-bold border-0 border-bottom "> Mail Details </Label>

                                        </Col>

                                        {projectView?.mail_server?.name == "Mailchimp" &&
                                            <>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label className="form-label fw-bold"> API Key  </Label>   <br />    {projectView?.mail_server?.server_details?.filter(type => type?.name === "api_key")[0]?.value}
                                                    </div>
                                                </Col>
                                            </>
                                        }


                                        {(projectView?.mail_server?.name == "SendGrid" || projectView?.mail_server?.name == "Mailtrap" || projectView?.mail_server?.name == "Brevo") && <>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> Mail Mailer </Label>   <br />

                                                    {projectView?.mail_server?.server_details?.filter(type => type?.name === "mail_mailer")[0]?.value}
                                                </div>
                                            </Col>

                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> Mail Host </Label>   <br />

                                                    {projectView?.mail_server?.server_details?.filter(type => type?.name === "mail_host")[0]?.value}

                                                </div>
                                            </Col>

                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> Mail Port </Label> <br />

                                                    {projectView?.mail_server?.server_details?.filter(type => type?.name === "mail_port")[0]?.value}
                                                </div>
                                            </Col>

                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> Mail Username </Label> <br />

                                                    {projectView?.mail_server?.server_details?.filter(type => type?.name === "mail_username")[0]?.value}

                                                </div>
                                            </Col>

                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> Mail Password </Label> <br />

                                                    {projectView?.mail_server?.server_details?.filter(type => type?.name === "mail_password")[0]?.value}
                                                </div>
                                            </Col>


                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> From Name </Label>   <br />
                                                    {projectView?.mail_from_name}
                                                </div>
                                            </Col>

                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> From Address  </Label>   <br /> {projectView?.mail_from_address}
                                                </div>
                                            </Col>

                                        </>
                                        }


                                        <Col lg={12}>

                                            <Label className="form-label fw-bold border-0 border-bottom "> SMS Details </Label>

                                        </Col>

                                        {projectView?.sms_server?.name == "Twilio" && <>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> SID  </Label>   <br />     {projectView?.sms_server?.server_details?.filter(type => type?.name === "twilio_sid")[0]?.value}
                                                </div>
                                            </Col>

                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> Token </Label>   <br />  {projectView?.sms_server?.server_details?.filter(type => type?.name === "twilio_token")[0]?.value}
                                                </div>
                                            </Col>

                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> From Number  </Label>   <br />  {projectView?.sms_server?.server_details?.filter(type => type?.name === "twilio_from")[0]?.value}
                                                </div>
                                            </Col>
                                        </>
                                        }







                                    </Row>
                                </div>}



                                {isEdit && <div className="px-4 py-3">
                                    <Form onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                    }}>
                                        <Row>
                                            <Col lg={4}>
                                                <div className="mb-3">
                                                    <Label htmlFor="name" className="form-label">Name <span className='secondary'>*</span></Label>
                                                    <Input type="text" className="form-control px-2 py-1" id="name" placeholder="Enter name"
                                                        name='name'
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.name || ""}
                                                        invalid={submit && validation.errors.name ? true : false}
                                                    />

                                                </div>
                                            </Col>


                                            <Col lg={8} className='d-flex gap-3'>
                                                <div className="mb-3">
                                                    <Label htmlFor="mail_settings" className="form-label">Mail Settings  </Label>
                                                    <div className="form-check form-switch form-switch-left form-switch-md">
                                                        <Input
                                                            type="switch"

                                                            checked={validation.values.mail_settings}
                                                            onChange={(e) => {


                                                                validation.setValues({
                                                                    ...validation.values,
                                                                    mail_settings: e.target.checked
                                                                });
                                                            }}
                                                        />

                                                    </div>

                                                </div>

                                                <div className="mb-3">
                                                    <Label htmlFor="sms_settings" className="form-label">SMS Settings</Label>
                                                    <div className="form-check form-switch form-switch-left form-switch-md">
                                                        <Input
                                                            checked={validation.values.sms_settings}

                                                            type="switch"
                                                            onChange={(e) => {
                                                                validation.setValues({
                                                                    ...validation.values,
                                                                    sms_settings: e.target.checked
                                                                });
                                                            }}
                                                        />

                                                    </div>

                                                </div>



                                                <div className="mb-3">
                                                    <Label htmlFor="whatsapp_settings" className="form-label">Whatsapp Settings</Label>
                                                    <div className="form-check form-switch form-switch-left form-switch-md">
                                                        <Input
                                                            checked={validation.values.whatsapp_settings}

                                                            type="switch"
                                                            onChange={(e) => {
                                                                validation.setValues({
                                                                    ...validation.values,
                                                                    whatsapp_settings: e.target.checked
                                                                });
                                                            }}
                                                        />

                                                    </div>

                                                </div>
                                            </Col>



                                            <Col lg={4}>
                                                <div className="mb-3">
                                                    <Label htmlFor="roles" className="form-label">Select Mail Server    </Label>
                                                    <Select isDisabled={!validation.values.mail_settings || validation.values.mail_settings == 0}
                                                        value={validation.values.mail_server_id ? serverList?.filter(item => item?.id === validation.values.mail_server_id)?.map((item, index) => {
                                                            return (
                                                                { value: item.id, label: item.name }
                                                            );
                                                        }) : ""}

                                                        onChange={(e) => {
                                                            updateProject({
                                                                ...projectView,
                                                                mail_server_id: e?.value
                                                            }, projectView?.id);

                                                            validation.setValues({
                                                                ...validation.values,
                                                                mail_server_id: e?.value
                                                            });
                                                        }}

                                                        options={serverList?.filter(item => item?.type === "Mail")?.map((item, index) => {
                                                            return (
                                                                { value: item.id, label: item.name }
                                                            );
                                                        })}
                                                        styles={selectStyle('mail_server_id')}
                                                    />

                                                </div>
                                            </Col>

                                            <Col lg={4}>
                                                <div className="mb-3">
                                                    <Label htmlFor="roles" className="form-label">Select SMS Server   </Label>
                                                    <Select isDisabled={!validation.values.sms_settings || validation.values.sms_settings == 0}
                                                        value={validation.values.sms_server_id ? serverList?.filter(item => item?.id === validation.values.sms_server_id)?.map((item, index) => {
                                                            return (
                                                                { value: item.id, label: item.name }
                                                            );
                                                        }) : ""}

                                                        onChange={(e) => {

                                                            validation.setValues({
                                                                ...validation.values,
                                                                sms_server_id: e?.value
                                                            });

                                                            updateProject({
                                                                ...projectView,
                                                                sms_server_id: e?.value
                                                            }, projectView?.id);

                                                        }}

                                                        options={serverList?.filter(item => item?.type === "SMS")?.map((item, index) => {
                                                            return (
                                                                { value: item.id, label: item.name }
                                                            );
                                                        })}
                                                        styles={selectStyle('sms_server_id')}
                                                    />

                                                </div>
                                            </Col>


                                            <Col lg={4}>
                                                <div className="mb-3">
                                                    <Label htmlFor="whatsapp_server_id" className="form-label">Select Whatsapp Server   </Label>
                                                    <Select isDisabled={!validation.values.whatsapp_settings || validation.values.whatsapp_settings == 0}
                                                        value={validation.values.whatsapp_server_id ? serverList?.filter(item => item?.id === validation.values.whatsapp_server_id)?.map((item, index) => {
                                                            return (
                                                                { value: item.id, label: item.name }
                                                            );
                                                        }) : ""}

                                                        onChange={(e) => {

                                                            validation.setValues({
                                                                ...validation.values,
                                                                whatsapp_server_id: e?.value
                                                            });

                                                            updateProject({
                                                                ...projectView,
                                                                whatsapp_server_id: e?.value
                                                            }, projectView?.id);

                                                        }}

                                                        options={serverList?.filter(item => item?.type === "Whatsapp")?.map((item, index) => {
                                                            return (
                                                                { value: item.id, label: item.name }
                                                            );
                                                        })}
                                                        styles={selectStyle('whatsapp_server_id')}
                                                    />

                                                </div>
                                            </Col>

                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="mail_from_name" className="form-label">Mail From Name <span className='secondary'>*</span> </Label>
                                                    <Input type="text" className="form-control px-2 py-1" id="mail_from_name" placeholder="Enter mail from name"
                                                        name='mail_from_name'
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.mail_from_name || ""}
                                                        invalid={
                                                            submit && validation.errors.mail_from_name ? true : false
                                                        }
                                                    />

                                                </div>
                                            </Col>

                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="mail_from_address" className="form-label">Mail From Address  <span className='secondary'>*</span></Label>
                                                    <Input type="text" className="form-control px-2 py-1" id="mail_from_address" placeholder="Enter mail from address"
                                                        name='mail_from_address'
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.mail_from_address || ""}
                                                        invalid={
                                                            submit && validation.errors.mail_from_address ? true : false
                                                        }
                                                    />

                                                </div>
                                            </Col>

                                            <Col lg={12}>
                                                <div className="hstack gap-2 justify-content-end">
                                                    <button type="submit" className="btn btn-primary shadow-lg" id="addNewMember" onClick={() => {
                                                        setSubmit(true);
                                                    }}>{!isEdit ? "Add User" : "Save"}</button>
                                                </div>
                                            </Col>


                                            <Row className="row gy-1 mb-0" >
                                                <Col lg={12} className='px-1 mb-0'>

                                                    <h5 className=' mb-0'> Mail Settings</h5>
                                                </Col>
                                            </Row>

                                            <Row className="row  gy-1 mb-1 mt-1 px-2" >
                                                <Col lg={12} className='table px-1  mb-0'>
                                                    <Card className="mb-0 d-none d-md-flex mt-1 px-1 table-info border-0  rounded-0">
                                                        <CardBody className="px-1 py-0 px-md-2 py-md-0 border-0">
                                                            <div className="row d-lg-flex align-items-center px-0 py-2">
                                                                <div className="col-7 col-md-6 px-2">
                                                                    <h5 className="fs-15 mb-0"> Name</h5>
                                                                </div>

                                                                <div className="mb-1 col-5 col-md-5 d-flex gap-2 align-items-center">
                                                                    <h5 className="fs-15 mb-0">Value</h5>
                                                                </div>

                                                                <div className="mb-1 col-12 col-md-1 d-flex gap-2 align-items-center justify-content-end">
                                                                    <h5 className="fs-15 mb-0">Action</h5>
                                                                </div>
                                                            </div>

                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>



                                            <Row className="row gy-1 mb-1 " id="mail_server-list">
                                                {validation.values?.mail_server?.length !== 0 ?
                                                    (validation.values?.mail_server?.server_details)?.map((item, index) => (
                                                        <Col lg={12} key={index} className='mt-0' >
                                                            <Card className="mb-0 mt-0 border-0 border-bottom rounded-0 py-1">
                                                                <CardBody className="px-2 py-0">
                                                                    <div className="d-flex align-items-center row">
                                                                        <div className="col-4 col-md-6"> {item.name}   </div>
                                                                        <div className="col-6 col-md-5">  {item.value}   </div>
                                                                        <div className="col-1 col-md-1 d-flex gap-0 align-items-center justify-content-end">

                                                                            <button type="button" className="btn  btn-sm btn-icon btn25"
                                                                                onClick={(e) => { severDetailValidation.setValues(item); setSubmit(true); setServerModal(true); }}>
                                                                                <i className="ri-pencil-fill fs-18 primary"></i>
                                                                            </button>

                                                                        </div>
                                                                    </div>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    ))
                                                    :
                                                    <div className="noresult" >
                                                        <div className="text-center">
                                                            <h6 className="mt-2">Sorry! No Result Found</h6>
                                                            {/* <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
        orders for you search.</p> */}
                                                        </div>
                                                    </div>
                                                }
                                            </Row>


                                            <Row className="row gy-1 mb-0 mt-2"  >
                                                <Col lg={12} className='px-1 mb-0'>

                                                    <h5 className=' mb-0'> SMS Settings </h5>
                                                </Col>
                                            </Row>

                                            <Row className="row  gy-1 mb-1 mt-1 px-2" >
                                                <Col lg={12} className='table px-1  mb-0'>
                                                    <Card className="mb-0 d-none d-md-flex mt-1 px-1 table-info border-0  rounded-0">
                                                        <CardBody className="px-1 py-0 px-md-2 py-md-0 border-0">
                                                            <div className="row d-lg-flex align-items-center px-0 py-2">
                                                                <div className="col-7 col-md-6 px-2">
                                                                    <h5 className="fs-15 mb-0"> Name</h5>
                                                                </div>

                                                                <div className="mb-1 col-5 col-md-5 d-flex gap-2 align-items-center">
                                                                    <h5 className="fs-15 mb-0">Value</h5>
                                                                </div>

                                                                <div className="mb-1 col-12 col-md-1 d-flex gap-2 align-items-center justify-content-end">
                                                                    <h5 className="fs-15 mb-0">Action</h5>
                                                                </div>
                                                            </div>

                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>

                                            <Row className="row gy-1 mb-1 " id="sms_server-list">
                                                {
                                                    validation.values?.sms_server?.length !== 0 ?
                                                        (validation.values?.sms_server?.server_details)?.map((item, index) => (


                                                            <Col lg={12} key={index} className='mt-0' >
                                                                <Card className="mb-0 mt-0 border-0 border-bottom rounded-0 py-1">
                                                                    <CardBody className="px-2 py-0">
                                                                        <div className="d-flex align-items-center row">
                                                                            <div className="col-4 col-md-6"> {item.name}   </div>
                                                                            <div className="col-6 col-md-5"> {item.value}    </div>
                                                                            <div className="col-1 col-md-1 d-flex gap-0 align-items-center justify-content-end">
                                                                                <button type="button" className="btn btn-sm btn-icon btn25"
                                                                                    onClick={(e) => { severDetailValidation.setValues(item); setSubmit(true); setServerModal(true); }}>
                                                                                    <i className="ri-pencil-fill fs-18 primary"></i>
                                                                                </button>

                                                                            </div>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        ))
                                                        :
                                                        <div className="noresult" >
                                                            <div className="text-center">
                                                                <h6 className="mt-2">Sorry! No Result Found</h6>
                                                                {/* <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
        orders for you search.</p> */}
                                                            </div>
                                                        </div>
                                                }
                                            </Row>



                                            <Row className="row gy-1 mb-0 mt-2"  >
                                                <Col lg={12} className='px-1 mb-0'>

                                                    <h5 className=' mb-0'> Whatsapp Settings </h5>
                                                </Col>
                                            </Row>


                                            <Row className="row  gy-1 mb-1 mt-1 px-2" >
                                                <Col lg={12} className='table px-1  mb-0'>
                                                    <Card className="mb-0 d-none d-md-flex mt-1 px-1 table-info border-0  rounded-0">
                                                        <CardBody className="px-1 py-0 px-md-2 py-md-0 border-0">
                                                            <div className="row d-lg-flex align-items-center px-0 py-2">
                                                                <div className="col-7 col-md-6 px-2">
                                                                    <h5 className="fs-15 mb-0"> Name</h5>
                                                                </div>

                                                                <div className="mb-1 col-5 col-md-5 d-flex gap-2 align-items-center">
                                                                    <h5 className="fs-15 mb-0">Value</h5>
                                                                </div>

                                                                <div className="mb-1 col-12 col-md-1 d-flex gap-2 align-items-center justify-content-end">
                                                                    <h5 className="fs-15 mb-0">Action</h5>
                                                                </div>
                                                            </div>

                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>

                                            <Row className="row gy-1 mb-1 " id="whatsapp_server-list">
                                                {
                                                    validation.values?.whatsapp_server?.length !== 0 ?
                                                        (validation.values?.whatsapp_server?.server_details)?.map((item, index) => (


                                                            <Col lg={12} key={index} className='mt-0' >
                                                                <Card className="mb-0 mt-0 border-0 border-bottom rounded-0 py-1">
                                                                    <CardBody className="px-2 py-0">
                                                                        <div className="d-flex align-items-center row">
                                                                            <div className="col-4 col-md-6"> {item.name}   </div>
                                                                            <div className="col-6 col-md-5"> {item.value}    </div>
                                                                            <div className="col-1 col-md-1 d-flex gap-0 align-items-center justify-content-end">
                                                                                <button type="button" className="btn btn-sm btn-icon btn25"
                                                                                    onClick={(e) => { severDetailValidation.setValues(item); setSubmit(true); setServerModal(true); }}>
                                                                                    <i className="ri-pencil-fill fs-18 primary"></i>
                                                                                </button>

                                                                            </div>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        ))
                                                        :
                                                        <div className="noresult" >
                                                            <div className="text-center">
                                                                <h6 className="mt-2">Sorry! No Result Found</h6>
                                                                {/* <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
        orders for you search.</p> */}
                                                            </div>
                                                        </div>
                                                }
                                            </Row>

                                        </Row>
                                    </Form>
                                </div>}


                                <div className="modal fade" tabIndex="-1" aria-hidden="true" >
                                    <div className="modal-dialog modal-dialog-centered"    >
                                        <Modal size="md" backdrop={"static"} isOpen={serverModal} toggle={() => {
                                            setServerModal(!serverModal)
                                        }} centered>

                                            <ModalHeader className='bg-info-subtle p-3 bpBottom pb-2' toggle={() => { setServerModal(!serverModal) }}  >  {"Update "}  </ModalHeader>

                                            <Form onSubmit={(e) => {
                                                e.preventDefault();
                                                severDetailValidation.handleSubmit();
                                                return false;
                                            }}>

                                                <ModalBody >   <Row>  <Col lg={12}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="value" className="form-label">Value <span className='secondary'>*</span></Label>
                                                        <Input type="text" className="form-control px-2 py-1" id="value" placeholder="Enter value"
                                                            name='value'

                                                            onChange={severDetailValidation.handleChange}
                                                            onBlur={severDetailValidation.handleBlur}
                                                            value={severDetailValidation.values.value || ""}
                                                            invalid={submit && severDetailValidation.errors.value ? true : false}
                                                        />

                                                    </div>
                                                </Col>

                                                </Row>

                                                </ModalBody >
                                                <ModalFooter>
                                                    <div className="hstack gap-2 justify-content-end">
                                                        {/* <Button type="button"  color="light" onClick={() => { setServerModal(false);  }} > Save </Button>
 */}

                                                        <button type="submit" color="light" className="btn btn-primary shadow-lg" onClick={() => {
                                                            setServerModal(false);
                                                        }}>Save</button>

                                                    </div>
                                                </ModalFooter>
                                            </Form>
                                        </Modal>
                                    </div>
                                </div>

                            </SimpleBar>
                        </OffcanvasBody>
                    </Offcanvas>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Project;