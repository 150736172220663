import React, { useEffect, useState } from 'react';
import {
    Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner, Offcanvas, FormGroup,
    OffcanvasHeader, OffcanvasBody,
} from 'reactstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import SimpleBar from "simplebar-react";
import withRouter from "../../Components/Common/withRouter";
import * as Yup from "yup";
import { useFormik } from "formik";
 import { createSelector } from 'reselect';
import { useMediaQuery } from 'react-responsive';
import { FaCheck, FaTimes, FaPlus } from "react-icons/fa";
import { loginSuccess, apiError } from '../../slices/auth/login/reducer';
import { api, url } from "../../helpers/api";
import { ToastMessage } from "../../Components/Hooks/Function";
import { ToastContainer, toast } from 'react-toastify';

const Login = (props) => {
 

    const dispatch = useDispatch();
    const selectLayoutState = (state) => state;
    const [settingsList, setSettingsList] = useState([]);
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const history = useNavigate();
    const placement = isDesktop ? 'end' : 'start'; 
    const loginpageData = createSelector(
        selectLayoutState,
        (state) => ({
            user: state.Account.user,
            error: state.Login.error,
            // loading: state.Login.loading,
            errorMsg: state.Login.errorMsg,
        })
    );
    const {
        user, error,
        // loading, 
        errorMsg
    } = useSelector(loginpageData);


    const [passwordShow, setPasswordShow] = useState(false);
    const [open, setOpen] = useState(false);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    const getSettings = async () => {
         api.defaults.headers.common['Content-Type'] = 'application/json';
        
        try {
            const response = await api.get('settingsDetails', {});

            if (response.data.status === true) { 
                setSettingsList(response.data?.data); 
                localStorage.setItem("settings", JSON.stringify(response.data?.data)); 

            } else { 
                setSettingsList([]);  
                ToastMessage("error", response.data?.message);
            }


        } catch (err) {
            console.error(err.message);
        }

    };


    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true, 
        initialValues: {
            email: '',
            password: '', 
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please Enter Your Email"),
            password: Yup.string().required("Please Enter Your Password"),  
        }),
        onSubmit: async (values, { setSubmitting }) => {
            try { 
                await Login(values);
                setSubmitting(false); 
            } catch (err) {
                console.error(err.message);
            }
        }
    });
 

    useEffect(() => { 
        getSettings();  
    }, []);

    const Login = async (values) => {
        api.defaults.headers.common['Content-Type'] = 'application/json';
        try {
            const response = await api.post('login', values);
            if (response.data.status === true) {
                verifyUser(response.data?.token);
            } else {
                dispatch(apiError(response.data.message));
            }

        } catch (err) {
            console.error(err.message);

            ToastMessage("error", err.message);
        }
    }


    const verifyUser = async (token) => {

        api.defaults.headers.common["Authorization"] = "Bearer " + token;
        api.defaults.headers.common['Content-Type'] = 'application/json';
 
        const response = await api.get('userDetails', {});
        if (response.data.status === true) {
            localStorage.setItem("token", token);
            localStorage.setItem("authUser", JSON.stringify({
                email: response.data?.data.email,
                user: response.data?.data,
            }));

           // dispatch(loginSuccess(response.data?.data));

            history('/mail');
        } else {
            ToastMessage("error", response.data?.message);
        }
    }


    const forgetValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please enter your email"),
        }),
        onSubmit: async (values, { setSubmitting }) => {
            try {
                await forgetPassword(values);
                setSubmitting(false);
            } catch (error) {
                console.error("An error occurred:", error);
                setSubmitting(false);
                ToastMessage("error", err.message);
            }
        },
    });



    const forgetPassword = async (values) => {

        api.defaults.headers.common['Content-Type'] = 'application/json';
   

        try {
            const response = await api.post('forgetPassword', values);
            if (response.data.status === true) {

                forgetValidation.resetForm();

                setOpen(false);

                ToastMessage("success", response.data?.message);

            } else {
                ToastMessage("error", response.data?.message);
            }


        } catch (err) {
            console.error(err.message);
        }

    };

    document.title = "Login";
    return (
        <React.Fragment>
            <ParticlesAuth>
                <ToastContainer closeButton={false} />
                <div className="auth-page-content">
                    <Container>
                        <Row className="justify-content-center mt-4">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <div className='mb-4'>
                                                <Link to="/" className="d-inline-block auth-logo">
                                                    <img src={url + settingsList?.filter(type => type?.name === "logo")[0]?.value} alt="" />
                                                </Link>
                                            </div>
                                            <h4 className="text-primary">Login</h4>
                                        </div>
                                        {error && error ? (<Alert color="danger"> {error} </Alert>) : null}
                                        <div className="p-2 mt-4">
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}
                                                action="#">
                                                <div className="mb-3">
                                                    <Label htmlFor="email" className="form-label">Email</Label>
                                                    <Input
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="Enter email"
                                                        type="email"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.email || ""}
                                                        invalid={
                                                            validation.touched.email && validation.errors.email ? true : false
                                                        }
                                                    />
                                                    {validation.touched.email && validation.errors.email ? (
                                                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="mb-3">
                                                    {/* <div className="float-end">
                                                        <Link to="/forgot-password" className="text-muted">Forgot password?</Link>
                                                        </div> */}
                                                    <Label className="form-label" htmlFor="password-input">Password</Label>
                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                        <Input
                                                            name="password"
                                                            value={validation.values.password || ""}
                                                            type={passwordShow ? "text" : "password"}
                                                            className="form-control pe-5"
                                                            placeholder="Enter Password"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            invalid={
                                                                validation.touched.password && validation.errors.password ? true : false
                                                            }
                                                        />
                                                        {validation.touched.password && validation.errors.password ? (
                                                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                        ) : null}
                                                        <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" onClick={() => setPasswordShow(!passwordShow)} type="button" id="password-addon"><i className="ri-eye-fill align-middle"></i></button>
                                                    </div>
                                                </div>


                                                <div className="mt-4">
                                                    <Button color="success" disabled={error ? null : validation.isSubmitting ? true : false} className="btn btn-success w-100" type="submit">
                                                        {validation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                                                        Sign In
                                                    </Button>
                                                </div>
                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>

                                <Offcanvas isOpen={open} toggle={function noRefCheck() { }} direction={placement} className={`offcanvas-${placement} border-0 offcanvas-600`}     >
                                    <OffcanvasHeader className="d-flex align-items-center bg-primary  p-3  " toggle={function noRefCheck() { }}   >
                                        <span className="d-flex justify-content-between" >
                                            <span className="m-0 me-2 text-white">{"Forgot Password"}</span>
                                            <span className="fs-18  text-color-white px-1"  >
                                                <FaCheck className='cursor' style={{ color: "white" }} onClick={() => { forgetValidation.handleSubmit(); }} />
                                                <span className="fs-18  text-color-white cursor" onClick={() => { toggleDrawer() }}>   <FaTimes style={{ color: "white" }} /> </span>
                                            </span>
                                        </span>
                                    </OffcanvasHeader>
                                    <OffcanvasBody className="p-0">
                                        <SimpleBar className="h-100">
                                            <div className="p-4">
                                                <Form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    forgetValidation.handleSubmit();
                                                    return false;
                                                }}>
                                                    <Row>
                                                        <Col lg={12}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="email" className="form-label">Email</Label>
                                                                <Input type="text" className="form-control" id="email" name='email'
                                                                    onChange={forgetValidation.handleChange}
                                                                    onBlur={forgetValidation.handleBlur}
                                                                    value={forgetValidation.values.email || ""}
                                                                    placeholder="Enter Email ID"
                                                                    invalid={
                                                                        forgetValidation.touched.email && forgetValidation.errors.email ? true : false
                                                                    }
                                                                />

                                                            </div>
                                                        </Col>
                                                        <Col lg={12}>
                                                            <div className="hstack gap-2 justify-content-end">
                                                                <Button color="success" type="submit" disabled={forgetValidation.isSubmitting ? true : false}
                                                                    className="btn btn-success"  >
                                                                    {forgetValidation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                                                                    {"Save"}
                                                                </Button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </div>
                                        </SimpleBar>
                                    </OffcanvasBody>
                                </Offcanvas>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default withRouter(Login);